import styled from '@emotion/styled'
import { Link } from 'gatsby'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import ColumnH1 from '../atoms/columnH1'
import Introduction003 from './Introduction003'

const Wrapper = styled.article`
  position: relative;
  margin-bottom: 30px;
  .p-txt-date {
    margin-top: -10px;
    color: #ababab;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 16px;
    color: #505050;
    line-height: 1.6em;
    h1 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #f5f5f5;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-bottom: 30px;
      margin-top: 10px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #77d7c9;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h2 {
      color: #414141;
      font-size: 22px;
      line-height: 1.4em;
      background: #ffffff;
      border-radius: 3px;
      position: relative;
      width: 100%;
      padding: 5px 5px 5px 20px;
      margin-top: 10px;
      margin-bottom: 30px;
      &:before {
        content: '';
        position: absolute;
        width: 10px;
        background: #d8d8d8;
        left: 0;
        top: 0;
        border-radius: 3px 0 0 3px;
        height: 100%;
      }
    }
    h3 {
      color: #414141;
      font-size: 16px;
      line-height: 1.4em;
      background: #ffffff;
      width: auto;
      padding: 10px 0 8px;
      margin-top: -10px;
      margin-bottom: 20px;
      border-top: solid 2px #e5e5e5;
      border-bottom: solid 2px #e5e5e5;
    }
    p {
      padding-bottom: 30px;
      line-height: 2;
      position: relative;
      span {
        background: linear-gradient(transparent 60%, #ff6 60%);
        font-weight: 600;
      }
      .p-list-link {
        position: absolute;
        top: 6px;
        right: 2px;
        &:before {
          display: block;
          content: '';
          position: absolute;
          width: 0;
          height: 0;
          top: 7px;
          left: -14px;
          border-left: 8px solid #3ec7b3;
          border-top: 4px solid transparent;
          border-bottom: 4px solid transparent;
        }
      }
      &.txt-color-red {
        color: #ff6969;
      }
    }
    .link-div {
      width: 100%;
      display: flex;
      justify-content: left;
      padding-bottom: 30px;
      > div {
        width: 50px;
      }
      .link-p {
        width: calc(100% - 50px);
        .p-list-link {
          position: absolute;
          top: -3px;
          left: 30px;
          &:before {
            display: block;
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            top: 11px;
            left: -14px;
            border-left: 8px solid #3ec7b3;
            border-top: 4px solid transparent;
            border-bottom: 4px solid transparent;
          }
        }
      }
    }
    img {
      width: 100%;
      height: auto;
      padding-bottom: 30px;
    }
  }
  ${BreakPoints.large} {
  }
`

/**
 * コラム記事詳細：記事本体
 * @constructor
 */
const ColumnDetailInner0021 = () => (
  <Wrapper>
    <ColumnH1 label="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説" />
    {/** TODO 川上申し送り事項：▼投稿日をここに表示 */}
    <p className="p-txt-date">2022.04.15</p>
    <section>
      {/** TODO 川上申し送り事項：▼記事投稿（？）で作成された内容がここに入る予定 */}
      <p>
        日本の労働法制上、休暇に対して賃金支払いが義務付けられているものは労働基準法で規定されている年次有給休暇のみです。例えば育児介護休業法で規定されている子の看護休暇や介護休暇は、賃金支払い義務がありません。
        <br />
        今回は労働法制上、<span>唯一賃金支払い義務が課せられる</span>
        有給付与について、掘り下げて解説してまいります。
      </p>
      <img src="/images/column/details/c0021.jpg" alt="キンクラコラム画像21" />
      <h1>年次有給休暇が付与される要件とは</h1>
      <p>
        労働基準法第39条1項では職場に雇入れた日から起算して
        <span>6か月継続勤務し、8割以上出勤</span>
        した労働者に対して10日付与されます。これは最低基準であり、これを下回ることはできません（パートなどで労働契約の前提となる出勤日数が少ない場合は10日より少ない日数で付与）。尚、「継続勤務」の考え方は労働契約自体が存続している場合は通算して考えます。
        <br />
        例えば定年退職後再雇用された場合や休職期間中であっても継続勤務として扱うということです。よって、雇入れ当初が全員同じということは稀であり、個人ごとにいつ有給休暇が付与されるのかは異なることから、人が増えるごとに管理は煩雑になると言えるでしょう。また、「8割以上」の考え方については「全労働日」の8割以上と考えます。
        <br />
        <br />
        全労働日とは端的には労働者として働くべき日（所定労働日）という趣旨であり、休日は除きます。
      </p>
      <h1>年次有給休暇の対象者</h1>
      <p>
        正社員はもちろんパートやアルバイトなど（呼称は会社によって異なる）であっても付与される対象者に含まれます。派遣労働者に対しては労働契約の締結は派遣元企業と派遣労働者の間で取り交わすことから
        <span>派遣元企業との間で存在する付与要件に沿って有給休暇が付与</span>
        されます。しかし、実務上は労務の提供先は派遣先企業であることから派遣先企業の所属長と派遣労働者との間でコミュニケーションを取り（繁忙期であれば尚更）取得日を決めることとなります。
      </p>
      <h1>付与される日数</h1>
      <p>
        いわゆる正社員であれば
        <span>6か月経過時に（出勤率に問題がなければ）10日が付与</span>
        されます。尚、有給休暇の時効は2020年4月の法改正後も2年が維持されています（残業代は3年に延長）。また、「比例付与」と言い、下記の2つの要件に当てはまる場合は、10日より少ない日数で付与しても違法ではありません。
        <br />
        <br />
        比例付与
        <br />
        ・週の所定労働時間が30時間未満
        <br />
        かつ
        <br />
        ・就の所定労働日数が4日以下または年間の所定労働日数が216日以下
      </p>
      <div className="link-div">
        参考
        <p className="link-p">
          <Link
            className="p-list-link"
            to="https://www.mhlw.go.jp/new-info/kobetu/roudou/gyousei/dl/140811-3.pdf"
            target="_blank"
            rel="noreferrer"
          >
            厚生労働省
          </Link>
        </p>
      </div>
      <p className="txt-color-red">
        尚、契約変更により年度の途中で労働者の所定労働日数が変更となった場合、対象者の残有給休暇日数を即座に増減する必要はありません。有給休暇は「基準日」において発生することから、次回の基準日に変更後の契約にあわせた日数を付与します。
      </p>
      <Introduction003 />
      <h1>年次有給休暇の付与の仕方（通常とは異なる付与の仕方等）</h1>
      <h2>時間単位年休</h2>
      <p>
        本来、有給休暇は労働者の心身のリフレッシュ等を目的に設けられており、原則として
        <span>「暦日」を単位とした取得</span>が想定されています。
        <br />
        しかし、労働者の中には暦日単位よりも時間単位の方が取得しやすいという諸般の事情もあり、
        <span>法律上は5日以内に限り、時間単位の取得も認められています。</span>
        <br />
        実務上は労使協定の締結が必要となりますが、所轄労働基準監督署への届出は不要です。
      </p>
      <h2>計画的付与</h2>
      <p>
        端的に言うと、<span>会社が有給休暇の取得日を決める</span>
        ことです。例えば当初会社としての休日として定められていた年末年始の休日の前後にプラス1日で有給休暇を付与（計画的付与）するなどの活用例が挙げられます。実務上は労使協定を締結しますが、時間単位年休と同様に所轄労働基準監督署への届出は不要です。
        <br />
        <br />
        付与できる日数は各労働者の繰り越し分も含めた有給休暇残日数の5日を超える部分が付与できる日数となります。例えば有給休暇が10日ある労働者の場合は5日、12日ある場合は7日まで計画的付与の対象とすることができます。
        <br />
        しかし、留意点として計画的付与の対象となる有給休暇の日数が不足している場合や請求権のない労働者も含めて計画的付与を行う場合は、付与日数を増やすなどの措置を取る必要があります。他には、計画的付与を個人別や班別に付与することも可能です。
      </p>
      <h2>時季指定義務</h2>
      <p>
        2019年4月1日から有給休暇が年10日以上
        <span>
          付与される労働者に対して付与日から1年以内に5日取得させることが義務化
        </span>
        されました。尚、労働者が様々な理由により有給休暇を請求しない場合であっても年に5日は消化させる必要があることから労働者の希望を聴取し、取得時季を指定することが求められます。当然「年10日以上」付与される労働者が対象となることから、比例付与対象者であっても一部を除き対象となります。
      </p>
      <h1 className="read">最後に</h1>
      <p>
        有給休暇は前提となる考え方として要件を満たした労働者に対して法律上当然に権利が発生するものであり、一部を除きどのような理由で取得するのか会社が干渉できる余地はありません。しかし、複数の労働者が繁忙期かつ同じ日に取得が重なった場合は「時季変更権」と言い、他の時季に休暇を与えることができます。
        <br />
        時季変更権の行使は<span>「事業の正常な運営を妨げる」場合</span>
        に行使でき、どのような場合も行使できるわけではありませんので、留意しておきたい部分です。
      </p>
    </section>
  </Wrapper>
)

export default ColumnDetailInner0021
