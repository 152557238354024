import { graphql } from 'gatsby'
import React from 'react'
import ColumnBackAndForth from '../../../components/atoms/columnBackAndForth'
import SEO from '../../../components/atoms/seo'
import { ITopicPath } from '../../../components/atoms/topicPath'
import ColumnAuthor001 from '../../../components/molecules/columnAuthor001'
import ColumnDetailInner0021 from '../../../components/molecules/columnDetailInner0021'
import ColumnRelationCards0021 from '../../../components/molecules/columnRelationCards0021'
import UsefulFunctionLayout019 from '../../../components/molecules/usefulFunctionLayout019'
import ColumnDetailsBody from '../../../components/organisms/columnDetailsBody'
import ColumnLayout from '../../../components/organisms/columnLayout'
import useRouteParam from '../../../hooks/useRouteParam'

/**
 * コラム記事詳細画面
 * @constructor
 */
const C0021 = () => {
  const paths: ITopicPath[] = [
    { title: 'キンクラコラムTOP', link: useRouteParam('/column') },
    { title: 'コラム記事一覧', link: useRouteParam('/column/list') },
    {
      title: '有給休暇とは？付与される条件や日数について',
    },
  ]

  return (
    <ColumnLayout paths={paths}>
      <SEO
        title="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
        description="年次有給休暇とは唯一賃金支払い義務が課せられる休暇です。6か月継続勤務し8割以上出勤した労働者に対して10日付与されます。正社員やパートやアルバイトも対象です。"
        ogUrl="https://kintaicloud.jp/column/details/C0021/"
        ogType="article"
        ogTitle="年次有給休暇とは？付与の条件や日数、時期などを詳しく解説"
        ogDescription="年次有給休暇とは唯一賃金支払い義務が課せられる休暇です。6か月継続勤務し8割以上出勤した労働者に対して10日付与されます。正社員やパートやアルバイトも対象です。"
        ogSiteName="キンクラ - 客先常駐特化型のクラウド勤怠管理システム"
        ogImage="https://www.kintaicloud.jp/images/column/details/c0021.jpg"
      />
      <ColumnDetailsBody>
        {/** 記事本体 */}
        <ColumnDetailInner0021 />
        {/** オーサ情報 */}
        <ColumnAuthor001 />
        {/** お役立ち機能 */}
        <UsefulFunctionLayout019 />
        {/** 前後記事への遷移 */}
        <ColumnBackAndForth />
        {/** 関連記事 */}
        <ColumnRelationCards0021 />
      </ColumnDetailsBody>
    </ColumnLayout>
  )
}

export default C0021

export const pageQuery = graphql`
  query C0021 {
    site {
      siteMetadata {
        title
      }
    }
  }
`
